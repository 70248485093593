import React from 'react';
import { Helmet } from 'react-helmet';
import { makeStyles } from '@material-ui/core/styles';
import { useRouteMatch } from 'react-router';

import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Footer from '../../Components/Layout/Footer';
import { Images } from '../../Themes';
import LayoutStyles from '../../Components/Layout/Styles/LayoutStyles';
import SectionHero from '../../Components/PagesLeagues/SectionHero';
import SectionMatrice from '../../Components/PagesLeagues/SectionMatrice';
import SectionContent from '../../Components/PagesLeagues/SectionContent';
import FooterWello from '../../PagesLeagues/Wello/Footer';
import Seo from '../../Routing/Seo';
import { getCdnUrl } from '../../Services/ImageResizer';

const useStyles = makeStyles(theme => ({
  mainLayout: {
    backgroundColor: '#ffffff',
    padding: 0,
    margin: 0,
  },
  paragraph: {
    ...LayoutStyles.fullWidthLayout,
    margin: '4rem 2rem 2rem',
    fontsize: '1rem',
  },
  headingContainer: {
    ...LayoutStyles.fullWidthLayout,
    margin: 'auto',
    padding: theme.spacing(6, 0),
    '&>*': {
      color: '#fff',
    },
  },
  button: {
    backgroundColor: '#fff',
    color: '#000000de',
  },
}));

const OfferCare = () => {
  const classes = useStyles();
  const match = useRouteMatch();

  return (
    <>
      <Helmet>
        {Seo.title('Wello | Solution numérique care manager')}
        {Seo.description('Le professionnel organise la vie à domicile pour votre proche.')}
        {Seo.canonical(6, match.url)}
      </Helmet>
      <Grid className={classes.mainLayout}>
        {/* PROS NEWS SECTION */}
        <SectionHero
          title1="Une plateforme de coordination pour les care manager"
          body="Le professionnel organise la vie à domicile pour votre proche."
          imageMD={6}
          googlePlayBadge={Images.wello.googlePlayBadge}
          appStoreBadge={Images.wello.appStoreBadge}
          backgroundImage={`url(${getCdnUrl(`https://www.wello.fr/${Images.offers.care.jumbo}`)})`}
          layoutHeight="92vh"
        />
        <Divider style={{ padding: '5rem', background: 'none' }} />
        {/* MATRICE SECTION */}
        <Typography variant="h2" align="center" gutterBottom>
          Qu’est-ce que le care management ?
        </Typography>
        <SectionMatrice
          rowItemSM={3}
          features={[
            {
              id: 57,
              image: getCdnUrl(`https://www.wello.fr/${Images.offers.care.matrice_feature1}`, 300),
              title: '1. Evaluation des besoins',
              subTitle:
                'Le care manager fait l’évaluation globale des envies de la personne et de ses ressources sur 4 axes.',
            },
            {
              id: 58,
              image: getCdnUrl(`https://www.wello.fr/${Images.offers.care.matrice_feature2}`, 300),
              title: '2. Définition du plan d’aide',
              subTitle:
                'Le plan d’aide permet de s’assurer de la complétude des services à mettre en œuvre.',
            },
            {
              id: 59,
              image: getCdnUrl(`https://www.wello.fr/${Images.offers.care.matrice_feature3}`, 300),
              title: '3. Coordination des services',
              subTitle:
                'Le care manager contacte le réseau informel et les structures professionnelles ou bénévoles.',
            },
            {
              id: 60,
              image: getCdnUrl(`https://www.wello.fr/${Images.offers.care.matrice_feature4}`, 300),
              title: '4. Suivi des interventions',
              subTitle:
                'Le care manager peut suivre le bon déroulement des interventions et la réalisation du plan d’aide.',
            },
          ]}
        />
        <Grid className={classes.paragraph}>
          <Typography variant="body1" gutterBottom>
            <p>
              Présent depuis plusieurs années dans de nombreux pays (Japon, Canada, Pays de Nord..),
              le « <b>care management</b> », plus communément appelé « case management », désigne «{' '}
              <b>
                un champ professionnel nouveau, à l’interface des secteurs sanitaires et sociaux.¹
              </b>{' '}
              » (Corvol et al, 2012). Destiné à améliorer les prises en charge complexes, il s’est
              peu à peu développé en France, sous l’impulsion du{' '}
              <a
                href="https://www.cairn.info/revue-gerontologie-et-societe1-2009-1-page-13.htm"
                target="_blank"
                rel="noreferrer"
              >
                Plan national Alzheimer
              </a>{' '}
              (2008-2012), pour répondre aux différents enjeux actuels et futurs de solidarité liés
              au domaine du care.
            </p>
            <p>
              Considéré comme une véritable solution innovante et inspirante, il émerge dans
              l’optique d’
              <b>
                orienter et accompagner les personnes en perte d’autonomie et leurs proches aidants
                face « aux cloisonnements et à l’émiettement des services de soins et d’aide à
                domicile sur les territoires
              </b>
              » (
              <a
                href="https://solidarites-sante.gouv.fr/ministere/documentation-et-publications-officielles/rapports/personnes-agees/article/rapport-el-khomri-plan-de-mobilisation-nationale-en-faveur-de-l-attractivite"
                target="_blank"
                rel="noreferrer"
              >
                Rapport El Khomri, 2019
              </a>
              ).
            </p>
            <p>
              Véritable chef d’orchestre, le « care manager » joue à la fois le rôle de{' '}
              <b>facilitateur, coordinateur et connecteur</b> entre la personne dépendante et les
              acteurs participant à son maintien à domicile. Ainsi, pour améliorer durablement le
              suivi, la qualité des soins et dans un souci d’efficience, son activité s’articule
              autour de quatre missions allant de{' '}
              <b>
                l’évaluation initiale des besoins à la réception des services proposés en passant
                par l’orientation de la personne et l’amélioration de la coordination des acteurs
                autour d’elle.
              </b>
            </p>
            <p>
              Ce nouveau paradigme managérial², encouragé par l’instauration de care manager et en
              faveur d’une meilleure <b>dynamique collective</b> interroge quant aux
              dispositifs/outils nécessaires à l’exercice de cette profession multidimensionnelle,
              intensive et sur le long terme³. Ainsi, pour optimiser le suivi, faciliter la
              transmission d’information et la coordination des acteurs, il semblerait intéressant,
              comme le souligne l&apos;institut Montaigne à travers sa note «{' '}
              <a
                href="https://www.institutmontaigne.org/ressources/pdfs/publications/favoriser%20le%20bien-vieillir.pdf"
                target="_blank"
                rel="noreferrer"
              >
                Bien vieillir à domicile : accompagner les seniors
              </a>{' '}
              » , <b>de s’appuyer sur des outils numériques</b>.
            </p>
          </Typography>

          <Divider style={{ padding: '0.5rem', background: 'none' }} />
          <Typography variant="caption" gutterBottom>
            <p>
              ¹ Corvol, A., Moutel, G., Somme, D. (2012). La gestion de cas en gérontologie :
              nouveau métier, nouvelles questions.
              <br />² Meyronin, B. (2020). Manager l&apos;expérience Client-Collaborateur: Vers
              l&apos;éthique du care. Paris: Dunod.
              <br />³ Somme, D., Corvol, A., Couturier, Y., Pimouguet, C., Moreau, O., Perivier, S.,
              Balard, F., de Stampa, M. (2015). Nouveau champ professionnel en France. Les besoins
              de formation des gestionnaires de cas.
            </p>
          </Typography>
        </Grid>

        <Divider style={{ padding: '3rem', background: 'none' }} />

        {/* CONTENT SECTION */}
        <SectionContent
          title="1. L’évaluation globale du bénéficaire"
          paragraph="Lors des premiers échanges, le care manager fait l’évaluation globale des besoins du bénéficiaire selon 4 axes majeurs. Les souhaits exprimés par le bénéficiaire lui-même sont également pris en compte et sont au coeur de son évaluation."
          image={getCdnUrl(`https://www.wello.fr/${Images.offers.care.feature1}`, 800)}
          rowItemTextSM={5}
        />
        <Divider style={{ padding: '1rem', background: 'none' }} />
        <SectionContent
          title="2. Définition du plan d’aide"
          paragraph="Le care manager accompagne l’aidant pour définir l’ensemble des services et des actions à mettre en place pour le bénéficiaire. Des outils permettent au care manager de balayer l’ensemble des besoins, et de faire des suggestions à l’aidant sur tous les axes (santé, services à domicile, vie sociale, etc.)."
          image={getCdnUrl(`https://www.wello.fr/${Images.offers.care.feature2}`, 800)}
          imageOnLeft
          imageUnderText={getCdnUrl(`https://www.wello.fr/${Images.offers.care.matrice}`, 800)}
        />
        <Divider style={{ padding: '1rem', background: 'none' }} />
        <SectionContent
          title="3. Coordonner les intervenants 
          et les services "
          paragraph="Le care manager contacte les structures professionnelles pour les actes techniques et contacte la famille, les amis, les voisins pour les petits gestes du quotidien. Enfin, le care manager prend contact avec des associations de bénévoles ou d’autres professionnels lorsqu’il n’y a pas de solutions dans le réseau proche du bénéficiaire. 
          <p>Basé sur le <a href='https://fr.wikipedia.org/wiki/Sociogramme' target='_blank' rel='noreferrer'>sociogramme de Jacob Levy Moreno</a> cette approche permet de s’assurer de la complémentarité
          des membres du réseau.
        </p>
        "
          image={getCdnUrl(`https://www.wello.fr/${Images.offers.care.feature3}`, 800)}
          rowItemTextSM={5}
        />
        <Divider style={{ padding: '1rem', background: 'none' }} />
        <SectionContent
          title="4. Suivre les interventions au quotidien"
          paragraph="Chaque membre peut en un simple clic notifier son passage ou envoyer un message aux autres membres. Le care manager peut suivre le bon déroulement du maintien à domicile et intervenir si besoin."
          image={getCdnUrl(`https://www.wello.fr/${Images.offers.care.feature4}`, 800)}
          imageOnLeft
          rowItemTextSM={5}
        />
        <Divider style={{ padding: '2rem', background: 'none' }} />
        {/* BLUE BACKGROUND SECTION */}
        <Grid
          style={{
            backgroundImage: `url(${Images.offers.care.blue_jumbo})`,
            backgroundSize: 'cover',
          }}
        >
          <Divider style={{ padding: '4rem', background: 'none' }} />

          <Grid className={classes.headingContainer}>
            <Typography variant="h2" align="center" gutterBottom>
              Le métier de Care manager, une opportunité pour les services d’aide à domicile
            </Typography>
          </Grid>
          <Grid className={classes.paragraph}>
            <Typography style={{ color: '#fff' }} gutterBottom>
              <p>
                Considéré comme étant un atout indispensable à la prise en charge organisée,
                coordonnée et continue d’un individu en perte d’autonomie, le développement du
                métier de care manager est également
                <b>une véritable opportunité pour les services d’aide à domicile</b>. En effet, il
                permet d’une part de valoriser et favoriser «
                <b>
                  l’évolution de carrière pour les professionnels médicaux, paramédicaux,
                  médicosociaux et sociaux⁴
                </b>
                » et d’autre part
                <b>
                  de diversifier les compétences et proposer des services adaptés face aux
                  complexités des prises en charge.
                </b>
                À travers l’émergence de ce métier, les professionnels de santé auront l’opportunité
                d’enrichir leur éventail de compétences, liant celle de la santé et du management
                pour aider à l’organisation quotidienne des interventions et soulager les proches
                aidants.
              </p>
              <p>
                Ainsi, l’utilisation de l’outil Wello, favorisant la planification et la
                transmission d’informations entre les différents acteurs (professionnels et aidants)
                pourrait d’une part <b>faciliter les missions du care manager</b> et d’autre part
                s&apos;avérer indispensable aux proches aidants,
                <b>désireux d’être inclus dans cette coordination.</b>
              </p>
            </Typography>
            <Typography variant="caption" gutterBottom style={{ color: '#fff' }}>
              ⁴ El Khomri, M. (2019). Plan de mobilisation nationale en faveur de l’attractivité des
              métiers du grand-âge.
            </Typography>
          </Grid>

          <SectionContent
            subtitle="Augmenter votre valeur et vos tarifs de prestation "
            paragraph="Les aidants ont besoin d’être rassurés sur le bon déroulement du maintien à domicile.
            Proposez à vos intervenants de remonter les informations du quotiden et valoriser ce service numérique auprès des aidants."
            image={getCdnUrl(`https://www.wello.fr/${Images.offers.care.daily_carenotebook}`, 800)}
            imageUnderText={getCdnUrl(`https://www.wello.fr/${Images.offers.care.selfie}`, 600)}
            rowItemTextSM={5}
            textColor="#fff"
          />

          <Divider style={{ padding: '3rem', background: 'none' }} />

          <SectionMatrice
            rowItemSM={4}
            title="Les avantages"
            textColor="#fff"
            features={[
              {
                id: 61,
                image: getCdnUrl(`https://www.wello.fr/${Images.offers.care.benefice_pro}`),
              },
              {
                id: 62,
                image: getCdnUrl(`https://www.wello.fr/${Images.offers.care.benefice_primary}`),
              },
              {
                id: 63,
                image: getCdnUrl(`https://www.wello.fr/${Images.offers.care.benefice_helper}`),
              },
            ]}
          />

          <Divider style={{ padding: '3rem', background: 'none' }} />

          <Grid className={classes.headingContainer} align="center">
            <Typography variant="h2" gutterBottom>
              Combien puis-je valoriser ce service ?
            </Typography>
            <Typography
              variant="h4"
              component="h3"
              style={{ fontWeight: 400, margin: 32 }}
              gutterBottom
            >
              Nous pouvons vous accompagner pour commercialiser ce service numérique à forte valeur
              ajoutée auprès de vos clients
            </Typography>
            <Button href="mailto:hello@wello.fr" variant="contained" className={classes.button}>
              NOUS CONTACTER
            </Button>
          </Grid>

          <Divider style={{ padding: '4rem', background: 'none' }} />
        </Grid>
        <Divider style={{ padding: '5rem', background: 'none' }} />
        {/* TOOL SECTION */}
        <Typography variant="h2" align="center" gutterBottom>
          Des outils modernes pour le care management
        </Typography>
        <Typography className={classes.paragraph} gutterBottom>
          <p>
            Si de nombreux outils de coordination existent aujourd’hui sur le marché, la plupart
            d’entre eux sont trop souvent pensés pour les professionnels
            <b>mais n&apos;incluent pas l’aidant, le bénéficiaire et son réseau primaire.</b> Dans
            le cadre du care management, de nombreux acteurs interviennent auprès des personnes en
            perte d’autonomie. Ces intervenants ont des rôles différents et ils appartiennent à des
            structures différentes. La solution Wello permet d’accueillir la complexité de la
            gestion des droits d’accès et d&apos;habilitation en fonction de son appartenance à une
            structure (SAAD, association, etc.), et en fonction de son rôle (infirmier, assistante
            sociale, aidant, bénévole, etc.).
          </p>
          <p>
            Au-delà de la gestion du réseau social nécessaire à l’organisation du maintien à
            domicile, les plateformes de care management devront à terme également faciliter
            <b>
              la recherche et la mise en œuvre des services, la facturation et de la bonne
              réalisation de la prestation.
            </b>
          </p>
        </Typography>
        <SectionMatrice
          rowItemSM={4}
          features={[
            {
              id: 64,
              image: getCdnUrl(`https://www.wello.fr/${Images.offers.care.tool1}`, 600),
              title: 'Recherche de prestataires géolocalisée',
              subTitle: 'Recherchez des prestataires proches de votre bénéficiaire',
            },
            {
              id: 65,
              image: getCdnUrl(`https://www.wello.fr/${Images.offers.care.tool2}`, 600),
              title: 'Mise en relation',
              subTitle:
                'Envoyez un message lui permettant de rentrer en relation avec le bénéficiaire ou l’aidant.',
            },
            {
              id: 66,
              image: getCdnUrl(`https://www.wello.fr/${Images.offers.care.tool3}`, 600),
              title: 'Achat de prestations',
              subTitle: 'L’aidant peut commander directement en ligne la prestation',
            },
            {
              id: 67,
              image: getCdnUrl(`https://www.wello.fr/${Images.offers.care.tool4}`, 600),
              title: 'Paiement en ligne',
              subTitle: 'L’aidant peut régler en ligne la prestation',
            },
            {
              id: 68,
              image: getCdnUrl(`https://www.wello.fr/${Images.offers.care.tool5}`, 600),
              title: 'Suivi des prestations',
              subTitle: 'Suivez le bon déroulement des prestations',
            },
            {
              id: 69,
              image: getCdnUrl(`https://www.wello.fr/${Images.offers.care.tool6}`, 600),
              title: 'Alerte et satisfaction client',
              subTitle:
                'Recevez des notifications en cas de problème sur la réalisation de la prestation.',
            },
          ]}
        />
        {/* FOOTER SECTION */}
        <Divider style={{ padding: '2rem', background: 'none' }} />
        <FooterWello />
        <Footer />
      </Grid>
    </>
  );
};

export default OfferCare;
